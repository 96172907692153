.datatable-templating .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.datatable-templating {
    margin: 30px;
}
.actions-format{
    width: 70%;
    padding: 10px;
    background-color: #50dcaf;
    color: white;
}
.input-max{
    width: 100%;
}

.datatable-templating .p-inputtext{
    width: 100%;
}
.datatable-templating  .p-datatable .p-datatable-thead tr th{
    background-color: #F8FAFD ;
    color:#B2BBCB;
    border:none ;
}

.datatable-templating .p-datatable-wrapper{
    border:none;
    
}

.datatable-templating .p-datatable-wrapper table{
    border:none;
}

.datatable-templating .p-datatable .p-sortable-column .p-sortable-column-icon{
    color:#B2BBCB;
}

.datatable-templating .p-datatable .p-datatable-tbody > tr > td{
    font-weight: 600;
    color: #2A3F6A;
    border:none;
}

.datatable-templating .p-highlight{
    background-color: #E3F2FD !important;
}

.datatable-templating .p-datatable .p-datatable-tbody > tr{
    border:none;
}

.datatable-templating .p-paginator{
    font-weight: 600;
    border:none;
}

.datatable-templating .p-paginator .p-paginator-current{
    color: #2A3F6A;
}

.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

.expedientes-title{
    margin-bottom: 20px;
    color: #00416C;
    font-weight: 700;
    font-family: "Poppins";
}

@media screen and (max-width: 40em) {
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}

.botonera{
    margin:30px;
}
.botonera button{
    margin:10px;
}   
.botonera input{
    margin:10px;
    width:200px!important;
}

.boton-leido{
    background:green!important;
    border:white!important;
}