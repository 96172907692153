.menu-lateral > div:first-child {
  background-color: #eff5fc;
  color: $white;
}

.menu-lateral .k-widget.k-drawer.k-drawer-start {
  z-index: 4;
}

.menu-icon {
  background-color: $white !important;
}

.menu-item:hover {
  background-color: #0069d9;
  color: $white;
}

.pagina {
  margin: 25px;
}

.mb25 {
  margin-bottom: 25px;
}

.textAlignRight {
  text-align: right !important;
}
.textAlignCenter {
  text-align: center !important;
}
.textAlignLeft {
  text-align: left !important;
}

.status-label {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
  height: auto;
  width: 95px;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  text-align: center;
}

.status-label-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.status-label-red {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}

.status-label-blue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}

.status-label-grey {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.status-label-yellow {
  color: brown;
  background: lightyellow;
  border-color: gold;
}

.status-label-legacy {
  color: white;
  background: #88caff;
  border-color: blue;
}

.nav-container-left {
  // flex-basis:240px;
  width: 265px;
  // flex-basis:240px;
  // width:272px;
  // background: #EFF5FC;
  height: 100%;
}

.nav-container-right {
  width: 100%;
  background: white;
  height: 100%;
  box-shadow: 0px 0px 23px lightgrey;
  // border-top-left-radius: 3em 3em;
  // margin-top: 25px;
}

.k-drawer-container {
  background: white;
}

.menu-item {
  margin: 15px;
  border-radius: 15px;
}

.k-drawer {
  font-family: -apple-system, BlinkMacSystemFont, "segoe ui", "roboto", "oxygen",
    "ubuntu", "cantarell", "fira sans", "droid sans", "helvetica neue",
    "sans-serif";
}

.k-item-text {
  margin-left: 10px;
}

.svg-inline--fa {
  margin-left: 10px;
  margin-top: 5px;
}

.menu-item {
  background-color: #ccdcef;
  color: #007bff;
}

.avatar {
  float: right;
  margin: 10px !important;
}

.tablaExpedientes {
  margin: 15px;
}

.tablaAlertas {
  margin: 15px;
}
.alertas {
  padding: 30px;
}

.imagen {
  padding: 1.5em;
  vertical-align: middle;
  border-style: none;
  width: 100%;
}

#tabla-detalle-expediente td {
  padding-bottom: 1em;
}
#tabla-detalle-expediente td {
  //border: 1px solid rgb(93, 160, 214);
  color: #007bff;
  background-color: #ccdcef;
  margin-bottom: 20px;
}
#tabla-detalle-expediente td:hover {
  cursor: pointer;
  background-color: #0069d9;
  color: white;
}

#tabla-detalle-expediente tr:first-child {
  display: none;
}
#alertas h2,
#detalle-expediente h2 {
  padding: 20px;
  font-size: 1.2em;
}

/*
 * Tabla curvada
 */

.table-curved {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0px;
}
.table-curved {
  border: solid #ccc 1px;
  border-radius: 6px;
  border-left: 0px;
}

.table-curved tr {
  position: relative;
  border-radius: 26px;
  border-collapse: seperate;
  border-spacing: 0;
  background: #f8f8f8;
  background-position-x: 10px;
  padding: 10px 0;
  transition: background 0.1s;
}
.table-curved tr:hover {
  background: #eee;
}
.table-curved th {
  border-top: none;
}
.table-curved th:first-child {
  border-radius: 6px 0 0 0;
}
.table-curved th:last-child {
  border-radius: 0 6px 0 0;
}
.table-curved th:only-child {
  border-radius: 6px 6px 0 0;
}
.table-curved tr:last-child td:first-child {
  border-radius: 0 0 0 6px;
}
.table-curved tr:last-child td:last-child {
  border-radius: 0 0 6px 0;
}

.table-curved td:first-child:before {
  content: "";
  display: block;
  width: 10px;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 6px 0 0 6px;
  position: absolute;
}
.table-curved td.red:before {
  background: red;
}
.table-curved td.green:before {
  background: green;
}
.table-curved td.blue:before {
  background: blue;
}
.table-curved td.orange:before {
  background: orange;
}
.table-curved td:first-child {
  padding-left: 15px;
}
.table-curved td {
  position: relative;
  border-bottom: 10px solid white;
  border-spacing: 0px;
  padding: 10px;
}

/*
 * Fin tabla curva
 */

.p-listbox-item {
  margin: 15px !important;
  padding: 15px !important;
  background: #ccdcef !important;
  border-radius: 15px !important;
  color: #007bff !important;
}
.p-listbox {
  border: 0px solid white !important;
}

.alertCard {
  border-left: 5px solid blue;
}

.textAreaForm {
  width: 100%;
}
.p-highlight {
  background: #007bff !important;
  color: white !important;
}

.autocomplete-template .k-animation-container-shown,
.k-animation-container > .k-popup {
  -webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.75);
}

#tabla-detalle-expediente td {
  padding-bottom: 1em;
}
#tabla-detalle-expediente td {
  //border: 1px solid rgb(93, 160, 214);
  color: #007bff;
  background-color: #ccdcef;
  margin-bottom: 20px;
}
#tabla-detalle-expediente td:hover {
  cursor: pointer;
  background-color: #0069d9;
  color: white;
}

#tabla-detalle-expediente tr:first-child {
  display: none;
}
// #alertas h2, #detalle-expediente h2 {

//     padding: 20px;
//     font-size: 1.2em;
// }

.mt15 {
  margin-top: 15px;
}

.tablaDetalle .p-datatable-thead tr {
  background: #ccdcef !important;
}

.bell {
  color: #007bff;
  font-size: 25px;
}

.bell-disabled {
  color: grey;
}

.bellContainer {
  text-align: center;

  .p-overlay-badge {
    cursor: pointer;
  }
}

.p-badge {
  float: right;
  margin-top: -15px;
}
.tarjetaBellWrapper2 .p-badge,
.p-badge-loader {
  background: red !important;
}

.bellContainer .mainBadge {
  background: red;
  /*font-size: 0.75rem;
    font-weight: 700;
    min-width: 0.75rem;
    height: 1em;  
    margin-top: 15px!important;*/
}

.detalle-expediente-title {
  margin-bottom: 20px;
  display: flex;
}

.detalle-templating {
  padding: 30px;
}
.detalle-templating .referencia-editable {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
.detalle-templating .k-textbox {
  margin-right: 10px;
}
.detalle-templating .label-referencia {
  color: #b2bbcb;
  font-size: larger;
}

.detalle-templating .referencia-title {
  color: #b2bbcb;
  font-size: larger;
  margin-left: 15px;
  margin-right: 15px;
}
.contenedorBellAlerts {
  position: absolute;
}

#chat {
  position: fixed;
  bottom: 20px;
  right: 50px;
  cursor: pointer;
  color: #007bff;
  font-size: 55px;
}

.contenedorChat input {
  border-radius: 15px;
  margin-left: 15px;
}

.contenedorChat button {
  border-radius: 15px;
  margin-left: 10px;
  height: 40px;
  margin-bottom: 4px;
}

.rcw-conversation-container .rcw-header {
  background-color: #ccdcef !important;
  color: #007bff !important;
}

.rcw-launcher {
  background-color: #ccdcef !important;
  color: #007bff !important;
}

.sc-header--img {
  height: 100px;
}

.status-label {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
  height: auto;
  width: 95px;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.status-label-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.status-label-red {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.status-label-blue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.status-label-grey {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.status-label-yellow {
  color: brown;
  background: lightyellow;
  border-color: gold;
}
.status-label-legacy {
  color: #01294a;
  background: #88caff;
  border-color: #35a2fc;
  font-weight: bold;
  width: 40%;
}

.cabecera-form-detalle {
  padding-left: 0px !important;
  font-size: 24px !important;
  color: #00416c !important;
}
.cabecera-form-subdetalle {
  font-size: 16px !important;
  color: #a59d9dd9 !important;
  font-weight: bold;
}

/* BUSCADOR */
input.react-autosuggest__input {
  width: 100%;
  border-radius: 30px;
  padding: 10px;
  border: none;
  -webkit-box-shadow: 0px 5px 21px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 5px 21px 1px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 21px 1px rgba(0, 0, 0, 0.07);
}
input.react-autosuggest__input--focused {
  border: none;
  outline: none;
}
.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  color: #304784;
  top: 51px;
  width: 100%;
  border: 1px solid #e9ecef;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  height: 400px;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  // max-height: 100px;
  overflow-y: scroll;
  z-index: 2;
  text-align: left;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #e9ecef;
  .contenedorBellAlerts {
    background: white;
    box-shadow: -45px 0px 90px rgba(0, 0, 0, 0.2);
  }

  .tarjetaBellContent span {
    margin-left: 15px;
    margin-bottom: 5px;
  }
}

.bell-icon-container {
  margin-right: 20px;
}

.contenedorBellAlerts {
  position: absolute;
}
.contenedorBellAlerts {
  background: white;
  box-shadow: -45px 0px 90px rgba(0, 0, 0, 0.2);
}

.tarjetaBellWrapper2 {
  margin-bottom: 20px;

  max-height: 250px;
}
.containerBellLoading {
  height: 220px;
}

.tarjetaBellLoading {
  margin-bottom: 20px;

  background-color: #fff;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08),
    0 -1px 3px 0 rgba(0, 0, 0, 0.06);
  padding: 8px;
  position: absolute;
  border-radius: 2px;
  margin-bottom: 0;
  height: 200px;
  z-index: -1;
  overflow: hidden;
  width: 100%;

  &:only-child {
    margin-top: 0;
  }

  &:before {
    content: "";
    height: 110px;
    display: block;
    background-color: #ededed;
    box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
  }

  &:after {
    content: "";
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
}
@keyframes loader-animate {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
.bellTitle {
  color: grey;
  text-align: left;
}
.verTodosMensajes {
  color: #568fd5;
  cursor: pointer;
}
.titulosTarjetas {
  font-size: 14px;
  background-color: #f7f7f7;
  color: #b8b8b8;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  text-align: left;
  padding: 5px 10px;
}
.tarjetaBellContent {
  text-align: left;
  padding-left: 15px;
  margin-bottom: 5px;
  height: 50px;
  background: #fcfcfc;
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  cursor: pointer;
}

.documentoEstado {
  margin-right: 10px;
  padding: 3px;
}

.documentoEstado-Aprobado {
  background: green;
}
.documentoEstado-Rechazado {
  background: red;
}
.documentoEstado-Revision {
  background: gold;
}
.doc-abogado {
  background: #88caff;
}

.cabeceraAlertaBell {
  font-size: 20px;
  color: #595959;
}
.p-sidebar-right {
  width: 23em !important ;
}
.numExpedienteAlerta {
  font-weight: bold;
  color: #797e83;
}
.numExpedienteAlertaTotal {
  color: #989898;
}
.titulosTarjetasTitulo {
  font-weight: bold;
  color: #797e83;
}

.sc-chat-window .sc-user-input .sc-user-input--file-icon-wrapper {
  display: none;
}

.logo-container {
  cursor: default;
  // margin: -16px;
  // padding:15px;
}

.datatable-formexpediente {
  padding: 0px;
}

.menu-lateral {
  min-height: 100vh;
}

#listado-documentos {
  height: 70vh;
  overflow-y: scroll;
}
.selected {
  box-shadow: 0px 0px 15px grey;
}

.autocomplete-template input {
  color: black;
}

.menu-icon {
  display: none !important;
}

.mobile-visible {
  display: none;
}

.button-descargar-zip{
  float: right;
  margin: 25px 0px;
}
.button-documentacion-presentada{
  float: right;
  margin: 25px 10px 60px 10px;
}

@media screen and (max-width: 768px) {
  .app-logo {
    display: none;
  }
  .p-splitbutton-defaultbutton {
    display: none !important;
  }
  .sidebar {
    display: none;
  }
  .container-fluid-principal {
    padding-left: 20px !important;
  }
  .sc-header {
    margin-top: 75px;
  }
  .detalle-expediente-title {
    font-size: 18px;
  }

  #listado-documentos h4 {
    font-size: 18px;
  }

  #detalle-expediente {
    margin-top: 35px;
  }
  .menu-icon {
    display: block !important;
  }
  .menu-movil {
    margin-top: 20px !important;
   
  }
  .status-class-component {
    margin: 15px 0px;
  }
  .datatable-templating .p-datatable .p-datatable-tbody > tr {
    border: 1px solid black;
  }
  #alertas .p-selectable-row td {
    font-size: 13px;
  }
  .ant-picker-input {
    width: 40% !important;
  }
  .ant-picker-range {
    width: 100%;
  }

  .modal-container-pdf-viewer {
    margin-top: 5vh !important;
    width: 100% !important;
    z-index: 10000;
    justify-content: center !important;
    margin-left: -40px !important;
  }
  .rpv-default-layout-sidebar-headers,
  .rpv-default-layout-toolbar {
    display: none;
  }

  .expedientes-title {
    font-size: 20px;
    text-align: center;
  }
  #alertas h2 {
    padding: 0px;
  }

  .react-autosuggest__suggestions-container--open {
    width: 80%;
    font-size: 14px;
  }

  .cabecera-form-detalle {
    padding: 20px 50px 0px 0px!important;
  }


  .mobile-visible {
    display: block;
  }

  .container-ficha-expediente {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
   
  }

  #form-detalle-expediente{
      min-height: 90vh;
  }
  .col-name {
    width: 25% !important;
  }

  .actions-format{
      width:100%;
      text-align: center;
  }

  .formulario-subida-detalle-expediente{
    margin-bottom:25px;
  }

  .button-descargar-zip{
    float: right;
    margin: 25px 0px 0px 0px;
    width:100%;
    text-align:center;
  }
  .button-descargar-zip > button{
    padding: 8px 64px;
    margin-right: 20px;
  }

  .button-documentacion-presentada{
    float: right;
    margin: 25px 10px 60px 10px;
    width:100%;
    text-align:center;
  }
  .ocultar-movil{
    display: inherit!important;
  }
}
.ocultar-movil{
  display: none;
}
.modal-container-pdf-viewer {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.sidebar {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  height: calc(100vh - 70px);
  left: 0px;
  position: fixed;
  top: 60px;
  z-index: 900;
  overflow: hidden;
  .ant-menu-item {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
    }
  }
  .version,
  .versionApi {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}

.sidebar-open {
  width: 100px;
  transition: 0.5s;
}

.sidebar-collapsed {
  transition: 0.5s;
  width: 50px;
}

.input--year {
  width: 55px;
}