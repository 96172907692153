.recovery-password {
  width: 75%;
  display: block;
  margin: 0 auto;
  margin-top: 5%;
  .content {
    input {
      display: block;
      width: 100%;
    }
  }
}
