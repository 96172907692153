// Global variables here
$background-color: #D4D6E4;
$info-background: #151950;
$react-color: #53d2fa;
$link-color: #71A6FF;
$white: #FFFFFF;



$tiger-team: #FF6358;
$lemon-team: #FFD246;
$organic-team: #55AB1D;
$ocean-team: #28B4C8;

$gedescolor: #0069aa;
$secundarioMenu: #5e5e5e;
$menu-selected: #454545;

$base-theme:Bootstrap;
$skin-name:kendo-gedesco-boostrap;
$swatch-name:Bootstrap;
$border-radius: 0.25rem;
$accent: #007bff;
$secondary: #e4e7eb;
$info: #17a2b8;
$success: #28a745;
$warning: #ffc107;
$error: #dc3545;
$body-bg: #ffffff;
$body-color: #292b2c;
$component-bg: #ffffff;
$component-color: #292b2c;
$card-cap-bg: #f7f7f9;
$card-cap-color: #292b2c;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;