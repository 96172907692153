.login-base {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  background-color: #fafafa;
  // font-family:Roboto sans-serif;
}
.left-col {
  background: #72f7f1;
  background: linear-gradient(128deg, #72f7f1 0%, #5066bf 100%);
  color: white;
}

.right-row {
  height: 100%;
  padding: 30px;
}
.right-col .right-row div h2 {
  color: #25437c;
  font-weight: 700;
}
.p-inputtext.input-text-login {
  width: 100%;
  border: 1px solid #e9eff4;
  border-radius: 4px;
}

.p-inputtext.input-text-login.p-invalid {
  border-color: #f44336 !important;
}

.button-login {
  width: 100%;
}

.login-form {
  margin-top: 40px;
  font-weight: 500;
}

.login-form label {
  color: #25375b;
}

.login-form .input-block-form {
  margin-bottom: 20px;
  height: 6em;
}

.login-form .button-block-form {
  margin-top: 20px;
}

.link-block-form {
  margin-top: 20px;
  text-align: center;
}

.frame-1 {
  background-color: transparent;
  height: 57px;
  left: 104px;
  position: absolute;
  top: 350px;
  width: 590px;
}

.title {
  width: 100%;
}

.group-6 {
  background-color: transparent;
  height: 54px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 294px;
}

.juridico {
  background-color: transparent;
  height: 58px;
  left: 318px;
  position: absolute;
  top: 0px;
  width: 272px;
}

.nuestra-empresa-trat {
  background-color: transparent;
  height: auto;
  left: 103px;
  position: absolute;
  text-align: left;
  top: 958px;
  width: 293px;
}

.span1-vWZsXm {
  color: rgba(255, 255, 255, 0.87);
  font-family: "Roboto", Helvetica, Arial, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17px;
}
.span2-vWZsXm {
  color: rgba(255, 255, 255, 0.87);
  font-family: "Roboto", Helvetica, Arial, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17px;
  text-decoration: underline;
}
.la-plataforma-de-acc {
  background-color: transparent;
  height: auto;
  left: 103px;
  position: absolute;
  text-align: left;
  top: 440px;
  width: 479px;
}
.font-class-2 {
  color: white;
  font-family: "Roboto", Helvetica, Arial, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
}
.mobile-logo-container {
  visibility: hidden;
  display: none;
}
@media screen and (min-width: 1000px) {
  .form-wrapper {
    max-width: 60% !important;
    margin-left: 10% !important;
  }
}

@media screen and (max-width: 1000px) {
  .left-col {
    display: none;
  }
  .right-col {
    width: 100% !important;
    padding: 0px !important;
    .right-row {
      padding: 0px !important;
    }
    h2 {
      font-size: 1.25rem !important;
      text-align: center;
    }
  }
  .mobile-logo-container {
    visibility: visible !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
    background: linear-gradient(128deg, #72f7f1 0%, #5066bf 100%);
    padding: 90px;
    margin-top: -65px;
  }
  .mobile-logo {
    width: 100%;
    max-width: 500px;
  }
  .button-block-form {
    padding: 0px 80px !important;
  }
  .button-login {
    .p-button-label {
      font-size: 12px !important;
    }
  }
  .login-form {
    padding: 0px 30px 0px 30px !important;
  }
  .form-wrapper {
    margin-top: -80px;
    max-width: 400px !important;
  }
}

// @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
// @import url('https://fonts.googleapis.com/css?family=Roboto:500,400,700');
// @import url('https://fonts.googleapis.com/css?family=Roboto:500,400,700');
// * {
//   box-sizing: border-box;
// }
// .border-class-1 {
//   border: 1px solid var(--athens-gray);
// }
// .border-class-2 {
//   border: 1px solid transparent;
// }
// .font-class-1 {
//   font-family: 'Roboto', Helvetica, Arial, serif;
//   font-style: normal;
//   font-weight: 400;
// }
// .font-class-2 {
//   color: white;
//   font-family: 'Roboto', Helvetica, Arial, serif;
//   font-size: 30px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 39px;
// }
// .font-class-3 {
//   color: var(--cello);
//   font-family: 'Roboto', Helvetica, Arial, serif;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   letter-spacing: -0.25px;
// }
// .font-class-4 {
//   color: var(--tuna);
//   font-family: 'Roboto', Helvetica, Arial, serif;
//   font-size: 13px;
//   font-style: normal;
//   font-weight: 400;
//   letter-spacing: -0.16px;
//   line-height: 27px;
// }
// .font-class-5 {
//   color: var(--cello);
//   font-family: 'Roboto', Helvetica, Arial, serif;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   letter-spacing: 0.29px;
// }
// .font-class-6 {
//   color: var(--bay-of-many);
//   font-family: 'Roboto', Helvetica, Arial, serif;
//   font-size: 43px;
//   font-style: normal;
//   font-weight: 700;
// }
// .font-class-7 {
//   color: var(--white);
//   font-family: "Roboto", Helvetica, Arial, serif;
//   font-size: 12px;
//   line-height: 17px;
// }
// .loginprocuradores {
//   background-color: var(--alabaster);
//   height: 100vh;
//   margin: 0px;
//   min-height: 1080px;
//   min-width: 1920px;
//   position: relative;
//   width: 100%;
// }
// .loginprocuradores .acceder {
//   background-color: transparent;
//   color: white;
//   font-size: 18px;
//   height: auto;
//   left: 0.22%;
//   position: absolute;
//   text-align: center;
//   top: calc(50.00% - 10px);
//   width: 99.70%;
// }
// .loginprocuradores .acceso-clientes {
//   background-color: transparent;
//   height: auto;
//   left: 1235px;
//   position: absolute;
//   text-align: left;
//   top: 355px;
//   width: 402px;
// }
// .loginprocuradores .background-color {
//   background-color: var(--havelock-blue);
//   border-radius: 6px;
//   box-shadow: 0px 5px 13px rgba(176,176,176,1.0);;
//   height: calc(100.00% - 1px);
//   left: 1px;
//   position: absolute;
//   top: 1px;
//   width: calc(100.00% - 1px);
// }
// .loginprocuradores .buttonlogin {
//   background-color: transparent;
//   height: 51px;
//   left: 1235px;
//   overflow: hidden;
//   pointer-events: auto;
//   position: absolute;
//   top: 619px;
//   transition: all 0.2s ease;
//   width: 423px;
// }
// .loginprocuradores .contrasena-8KLG5n {
//   background-color: transparent;
//   height: auto;
//   left: 0px;
//   position: absolute;
//   text-align: left;
//   top: calc(50.00% - 30px);
//   width: 29.79%;
// }
// .loginprocuradores .contrasena-C61RwL {
//   background-color: transparent;
//   height: 60px;
//   left: 1236px;
//   overflow: hidden;
//   position: absolute;
//   top: 518px;
//   width: 423px;
// }
// .loginprocuradores .correo {
//   background-color: transparent;
//   height: 61px;
//   left: 1236px;
//   overflow: hidden;
//   position: absolute;
//   top: 438px;
//   width: 423px;
// }
// .loginprocuradores .correo-electronico {
//   background-color: transparent;
//   height: auto;
//   left: -0.24%;
//   position: absolute;
//   text-align: left;
//   top: calc(50.00% - 32px);
//   width: 37.12%;
// }
// .loginprocuradores .ejemplogedescoes {
//   background-color: transparent;
//   border: 0;
//   height: 27px;
//   left: 3.55%;
//   padding: 0;
//   position: absolute;
//   resize: none;
//   text-align: left;
//   top: 50.00%;
//   white-space: nowrap;
//   width: 73.76%;
// }
// .loginprocuradores .ejemplogedescoes::placeholder {
//   color: rgba(50,60,71,0.6);
// }
// .loginprocuradores .frame-1 {
//   background-color: transparent;
//   height: 57px;
//   left: 104px;
//   position: absolute;
//   top: 350px;
//   width: 590px;
// }
// .loginprocuradores .group-6 {
//   background-color: transparent;
//   height: 54px;
//   left: -0px;
//   position: absolute;
//   top: 2px;
//   width: 294px;
// }
// .loginprocuradores .he-olvidado-mi-contr {
//   background-color: transparent;
//   color: var(--jordy-blue);
//   font-size: 12px;
//   height: auto;
//   left: 1306px;
//   position: absolute;
//   text-align: center;
//   text-decoration: underline;
//   top: 689px;
//   width: 284px;
// }
// .loginprocuradores .input-field-8KLG5n {
//   background-color: white;
//   border-radius: 5px;
//   height: 60.00%;
//   left: -0.24%;
//   position: absolute;
//   top: 41.67%;
//   width: 100.47%;
// }
// .loginprocuradores .input-field-wLhuHx {
//   background-color: white;
//   border-radius: 4px;
//   box-shadow: 3px 2px 0px rgba(77,161,255,1.0) , 0px 2px 4px rgba(0,0,0,0.5);;
//   height: 57.38%;
//   left: -0.24%;
//   position: absolute;
//   top: 44.26%;
//   width: 100.47%;
// }
// .loginprocuradores .juridico {
//   background-color: transparent;
//   height: 58px;
//   left: 318px;
//   position: absolute;
//   top: 0px;
//   width: 272px;
// }
// .loginprocuradores .la-plataforma-de-acc {
//   background-color: transparent;
//   height: auto;
//   left: 103px;
//   position: absolute;
//   text-align: left;
//   top: 440px;
//   width: 479px;
// }
// .loginprocuradores .nuestra-empresa-trat {
//   background-color: transparent;
//   height: auto;
//   left: 103px;
//   position: absolute;
//   text-align: left;
//   top: 958px;
//   width: 293px;
// }
// .loginprocuradores .rectangle {
//   background-color: transparent;
//   height: 1080px;
//   left: 0px;
//   position: absolute;
//   top: 0px;
//   width: 960px;
// }
// .loginprocuradores .span1-vWZsXm {
//   color: rgba(255,255,255,0.87);
//   font-family: 'Roboto', Helvetica, Arial, serif;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   letter-spacing: 0.00px;
//   line-height: 17px;
// }
// .loginprocuradores .span2-vWZsXm {
//   color: rgba(255,255,255,0.87);
//   font-family: 'Roboto', Helvetica, Arial, serif;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   letter-spacing: 0.00px;
//   line-height: 17px;
//   text-decoration: underline;
// }
// .loginprocuradores .text {
//   background-color: transparent;
//   height: 41.18%;
//   left: 2px;
//   position: absolute;
//   top: 29.17%;
//   width: 422px;
// }
// .loginprocuradores .x {
//   background-color: transparent;
//   border: 0;
//   color: var(--tuna);
//   font-size: 12px;
//   height: 28px;
//   left: 3.55%;
//   line-height: 28px;
//   padding: 0;
//   position: absolute;
//   resize: none;
//   text-align: left;
//   top: 50.00%;
//   white-space: nowrap;
//   width: 36.41%;
// }
// .loginprocuradores .x::placeholder {
//   color: rgba(50,60,71,0.6);
// }
// :root {
//   --alabaster: rgba(250,250,250,1.0);
//   --athens-gray: rgba(233,239,244,1.0);
//   --bay-of-many: rgba(37,67,124,0.87);
//   --cello: rgba(36,55,90,1.0);
//   --havelock-blue: rgba(87,144,214,1.0);
//   --jordy-blue: rgba(141,184,239,1.0);
//   --tuna: rgba(50,60,71,1.0);
//   --white: rgba(255,255,255,0.87);
// }
